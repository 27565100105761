import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import moment from 'moment'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants


function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

const today = new Date();
today.setHours(0, 0, 0, 0);

  return Yup.object({
    tripNo: Yup.string().required(requiredMessage).matches("^[a-zA-Z0-9]+$"," Please enter alpha numeric").nullable().max(35,"Trip Number maximum length is 35").nullable(),
    etaFirstPortUS: Yup.string().required(requiredMessage).test(
      'etaFirstPortUS',
      'Please select a valid Date Time in yyyy-mm-dd HH:mm format.',
      value => {
        var formattedDate = moment(value).format('YYYY-MM-DD')
        var formattedToday = moment(today).format('YYYY-MM-DD')
             return moment(formattedDate).isValid();
                   }
                 ).nullable(),
    firstPort: Yup.string().required(requiredMessage).nullable(),
    inbond7512No: Yup.string().required(requiredMessage).matches("^[a-zA-Z0-9]+$"," Please enter alpha numeric").max(9,"Inbond 7512 Number maximum length is 9").nullable(),
    inbondDate: Yup.string().required(requiredMessage).test(
      'inbondDate',
      'Please select a valid Date Time in yyyy-mm-dd HH:mm format.',
      value => {
        var formattedDate = moment(value).format('YYYY-MM-DD')
        var formattedToday = moment(today).format('YYYY-MM-DD')
             return moment(formattedDate).isValid();
                   }
                 ).nullable(),
    inbondDestination: Yup.string().required(requiredMessage).nullable(),
    firmsCode: Yup.string().when(['messageCode', 'msgFunction'], {
      is: (messageCode, msgFunction) => (messageCode == '98' && msgFunction == '64'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    msgFunction: Yup.number().required(requiredMessage).nullable(),
    messageCode: Yup.number().required(requiredMessage).nullable(),
  })
}

export default makeValidationSchema
