import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, constants, DataFlattener, useTranslation } from 'cng-web-lib'
import InbondEditForm from 'src/views/aceHwyInbondArrival/InbondEditForm'
import ResponseMessage from 'src/views/aceHwyInbondArrival/ResponseMessage'
import { Box, Card, useTheme } from '@material-ui/core'
import Stepper from 'src/components/aciacehighway/Stepper'
import withFormState from 'src/components/aciacehighway/hocs/withFormState'
import pathMap from 'src/paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import AceHwyInbondArrivalApiUrls from 'src/apiUrls/AceHwyInbondArrivalApiUrls'
import { getStatusMetadata } from 'src/common/NACommon.js'
import axios from 'axios'
import ActivityLog from 'src/views/aceHwyInbondArrival/ActivityLog'
import FormProperties from './FormProperties'
import ChargeTypeDialog from 'src/views/common/ChargeTypeDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import { useFormStyles } from 'src/common/FormStyles'
import clsx from 'clsx'

const {
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
  const { history, loading, location, onSetLoading, showNotification } = props
  const { id } = useParams()
  const { state } = location
  const manifestData = useRef(state?.manifestData ? state.manifestData : null)
  const [step, setStep] = useState((state?.step ? state.step : 1) - 1)
  const [manifestStatus, setManifestStatus] = useState(state?.status ? state.status : null)
  const [stepIconColor, setStepIconColor] = useState({})
  const [completed, setCompleted] = useState({})
  const theme = useTheme()
  const formClass = useFormStyles()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })

  const [isSuperParty, setSuperParty] = useState(false);

  useEffect(() => {
    if (manifestData != null && manifestData != undefined) {
      if (manifestData.current != null && manifestData.current != undefined) {
        setManifestStatus(manifestData.current.status)
      }
    }
  }, []);

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            console.log("logged party:"+e,GetLoggedInUserPartyName);
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }
  useEffect(() => {
    if (manifestStatus) {
      if (manifestStatus !== '1005') {
        setCompleted({ 0: true })
      }

      if (manifestStatus === '1001') {
        const rejectedColor = getStatusMetadata(manifestStatus)

        setStepIconColor({ 0: rejectedColor })
      }
    }
  }, [manifestStatus])

  function makeTranslatedTextsObject() {
    let submitSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHighwayAbiInbond7512Keys.SUBMIT_SUCCESS_MSG
    )

    return { submitSuccessMsg }
  }

  function preSubmit() {
    const values = { ...manifestData.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR'
      manifestData.current = values
      handleSubmitManifest()
    }
  }

  function handleSubmitManifest() {
    onSetLoading(true)

    const values = { ...manifestData.current }
console.log("values:"+JSON.stringify(values));
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    var serverData = FormProperties.toServerDataFormat(values)
    console.log("values at 148:"+JSON.stringify(serverData));
    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    instance
      .post(AceHwyInbondArrivalApiUrls.SUBMIT, DataFlattener.unflatten(serverData))
      .then(
        (result) => {
          var returnData = result.data
          var errorMessages = returnData.errorMessages
          if (
            result.status == 200 &&
            returnData != null &&
            errorMessages == null
          ) {
            showNotification('success', translatedTextsObject.submitSuccessMsg)
            history.push(pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)
          } else {
            showNotification('error', errorMessages)
          }
        },
        (error) => {
          console.log(error)
        }
      )
      .finally(() => {
        onSetLoading(false)
      })
  }

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <InbondEditForm
            manifestData={manifestData}
            onChangeStep={(step) => setStep(step)}
            onSetLoading={onSetLoading}
            onSetManifestStatus={(status) => setManifestStatus(status)}
            onSubmitManifest={preSubmit}
            showNotification={showNotification}
            manifestStatus={manifestStatus}
            step={step}
            id={id}
          />
        )
      case 1:
        return (
          <ResponseMessage
            history={history}
            showNotification={showNotification}
            id={id}
            onSetLoading={onSetLoading}
            onSubmitManifest={handleSubmitManifest}
            onChangeStep={(step) => setStep(step)}
            step={step}
            // isABI={false}
          />
        )
      case 2:
        return (
          <ActivityLog
            id={id}
            history={history}
            onChangeStep={(step) => setStep(step)}
            onSubmitManifest={preSubmit}
            step={step}
          />
        )
    }
  }

  return (
    <>
      <Card className={clsx(formClass.form, formClass.inputs)}>
        <Stepper
          steps={['Inbond Arrival/Export', 'Inbond Response', 'Activity Logs']}
          activeStep={step}
          completed={completed}
          onSetActiveStep={(step) => setStep(step)}
          stepIconColor={stepIconColor}
        />
        <Box padding={2}>
          {renderContent(step)}
        </Box>
      </Card>
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          manifestData.current = data
          handleSubmitManifest()
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
    </>
  )
}

export default withFormState(EditPage)
