import AceHwyInbondArrivalApiUrls from 'src/apiUrls/AceHwyInbondArrivalApiUrls'
import FormProperties from './FormProperties'
import React, { useEffect, useState } from 'react'
import { components, constants, DataFlattener, useServices, useTranslation } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import AceHwyInbondArrivalKeys from 'src/constants/locale/key/AceHwyInbondArrival'
import { useLocation } from 'react-router-dom'
import { GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import { Box, Card, Grid } from '@material-ui/core'
import withFormState from '../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngAddForm },
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function AddPage({ history, onSetLoading, showNotification }) {
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_INBOND_ARRIVAL)
  const translatedTextsObject = makeTranslatedTextsObject()
  const loc = useLocation()
  let formikPropsData;
  if(loc.state !== undefined) {
    formikPropsData = {
      ...FormProperties.formikProps,
      initialValues: loc.state 
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const [isSuperParty, setSuperParty] = useState(false);

  useEffect(() => {
    Promise.all([
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(([billingSuperParty]) => {
      Object.keys(billingSuperParty).forEach(e => {
        if (e == GetLoggedInUserPartyName()) {
          setSuperParty(true);
        }
      })
    }
    )
  }, [])

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.SAVE_BUTTON
    )
    let submitButton = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.SUBMIT_BUTTON
    )
    return {
      saveButton,
      submitButton
    }
  }
  
  return (
    <Card>
      <Box padding={2}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          formikProps={formikPropsData}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            />
          }
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: AceHwyInbondArrivalApiUrls.POST,
            onSuccess: (e) => {
              showNotification('success', 'Created successfully.')
              history.push({pathname: `${pathMap.ACE_HIGHWAY_INBOND_ARRIVAL}/edit/`+e.id})
              onSetLoading(false)
            }
           // successRedirect: pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW
          }}
          // intervalToPromptUserToSubmit={60000}
          renderButtonSection={(loading) => (
            <CustomButtonSection
              history={history}
              isSuperParty={isSuperParty}
              loading={loading}
              onSetLoading={onSetLoading}
              saveButtonLabel={translatedTextsObject.saveButton}
              showNotification={showNotification}
              
            />
          )}
        />
      </Box>
    </Card>
  )
}

export default withFormState(AddPage)

function CustomButtonSection(props) {
  const {
    history,
    isSuperParty,
    loading,
    onSetLoading,
    saveButtonLabel,
    showNotification,
    submitButtonLabel
  } = props

  const { securedSendRequest } = useServices()
  const { getValues } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  function handleSubmit() {
    let values = { ...getValues() }

    if (!isSuperParty) {
      values.chargeAs = 'REGULAR'
    }

    securedSendRequest.execute(
      'POST',
      AceHwyInbondArrivalApiUrls.SUBMIT,
      DataFlattener.unflatten(FormProperties.toServerDataFormat(values)),
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages
        if (response.status == 200 && data != null && errorMessages == null) {
          showNotification('success', 'Submitted successfully')
          history.push(pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      }
    )
  }

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs='auto'>
        <CngButton
          color='secondary'
          disabled={loading}
          onClick={() => history.push(pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)}
          size='medium'
        >
          Discard
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='primary'
              disabled={loading}
              type='submit'
              size='medium'
              shouldShowProgress={loading}
            >
              {saveButtonLabel}
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

