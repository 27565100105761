import { Box, Chip, Typography } from '@material-ui/core'
import { components, constants, useTranslation, useServices } from 'cng-web-lib'

import AceHwyInbondArrivalApiUrls from 'src/apiUrls/AceHwyInbondArrivalApiUrls'
import React, { useEffect, useState, useRef } from 'react'
import AceHwyInbondArrivalKeys from 'src/constants/locale/key/AceHwyInbondArrival'
import AceHighwayAbiInbondEventKeys from 'src/constants/locale/key/AceHighwayAbiInbondEvent'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import Table from '../../components/aciacehighway/Table'
import ConfirmDialog from '../common/ConfirmDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pathMap from 'src/paths/pathMap'
import { getStatusMetadata } from '../../common/NACommon'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import InbondEventFormProperties from '../acehighway/abiInbondEvent/FormProperties'
import InbondArrivalFormProperties from './FormProperties'
import InbondEventUploadDialog from '../acehighway/abiInbondEvent/InbondEventUploadDialog'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { history, location, showNotification } = props

  const tableRef = useRef(null)
  const [lookups, setLookups] = useState(null)
  const [uploadInbondEventDialog, setUploadInbondEventDialog] = useState({ open: false, tableRef: null })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    inbondArrival: null
  })
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACE_HIGHWAY_INBOND_ARRIVAL
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const { createRecord, deleteRecord } = useServices()

  useEffect(() => {
    Promise.all([
      // Message code
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HWY_INBOND_TYPE' }],
        undefined,
        'code'
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      // Message Function
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'MESSAGE_FUNCTION_INBOND' }],
        undefined,
        'code'
      ),
      // Message Function
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_ABI_ACTION' }],
        undefined,
        'code'
      )
    ]).then(([messageCode, status, inbondMsgFunc, abiMsgFunc]) => {
      // let msgFunc = { ...inbondMsgFunc, ...abiMsgFunc }
      setLookups({ messageCode, status, inbondMsgFunc, abiMsgFunc })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let aceHwyInbondArrival = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: aceHwyInbondArrival
      }
    )
    let tripNo = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.TRIP_NO
    )
    let etaFirstPortUS = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.ETA_FIRST_PORT_US
    )
    let firstPort = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.FIRST_PORT
    )
    let inbondNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.INBOND_NUMBER
    )
    let inbondDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.INBOND_DATE
    )
    let inbondDestination = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.INBOND_DESTINATION
    )
    let arrivalPort = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ARRIVAL_PORT
    )
    let firmsCode = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.FIRMS_CODE
    )
    let msgFunction = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.MSG_FUNCTION
    )
    let actionCode = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.ACTION_CODE
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.STATUS
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.DELETE_BUTTON
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.ID
    )
    let createdBy = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.UPDATED_DATE
    )
    let submittedBy = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.SUBMITTED_DATE
    )
    let version = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.VERSION
    )
    let partyId = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.PARTY_ID
    )
    let cloneMultipleRecords = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.CLONE_MULTIPLE_RECORDS
    )
    let messageCode = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.MESSAGE_CODE
    )

    let masterBol = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.MASTER_BOL
    )
    return {
      aceHwyInbondArrival,
      tableTitle,
      id,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      version,
      partyId,
      messageCode,
      tripNo,
      etaFirstPortUS,
      firstPort,
      inbondNumber,
      inbondDate,
      inbondDestination,
      arrivalPort,
      firmsCode,
      msgFunction,
      actionCode,
      status,
      editButton,
      cloneButton,
      deleteButton,
      cloneMultipleRecords,
      masterBol
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: 'inbond7512No',
      sortKey: 'inbond7512No',
      title: translatedTextsObject.inbondNumber
    },
    {
      field: 'tripNo',
      sortKey: 'tripNo',
      title: translatedTextsObject.tripNo,
      render: (data) => (
        <Box>
          <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
            {data.tripNo}
          </Typography>
          <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
            {getLookupValue('messageCode', data.messageCode)}
          </Typography>
        </Box>
      )
    },
    {
      field: "masterBol",
      sortKey: 'masterBol',
      title: translatedTextsObject.masterBol,
      render: (data) => {
        if (data.masterBolIssuerCode && data.masterBol) {
          return (
            <Typography variant='inherit'>
              {data.masterBolIssuerCode + data.masterBol}
            </Typography>
          )
        }
      }
    },
    {
      field: 'inbondDestination',
      sortKey: 'inbondDestination',
      title: translatedTextsObject.inbondDestination+"/"+translatedTextsObject.arrivalPort
    },
    {
      field: 'inbondDate',
      sortKey: 'inbondDate',
      title: translatedTextsObject.inbondDate,
      render: (data) =>
        data.inbondDate &&
        moment(data.inbondDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'firstPort',
      sortKey: 'firstPort',
      title: translatedTextsObject.firstPort
    },
    {
      field: 'etaFirstPortUS',
      sortKey: 'etaFirstPortUS',
      title: translatedTextsObject.etaFirstPortUS,
      render: (data) =>
        data.etaFirstPortUS &&
        moment(data.etaFirstPortUS).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'msgFunction',
      sortKey: 'msgFunction',
      title: translatedTextsObject.actionCode,
      render: (data) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {data.messageCode ? getLookupValue('inbondMsgFunc', data.msgFunction) : getLookupValue('abiMsgFunc', data.msgFunction)}
          </Typography>
        </Box>
      )
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: translatedTextsObject.submittedDate,
      render: (data) =>
        data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor
            }}
          />
        )
      }
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.inbondNumber,
      type: 'textfield',
      name: 'inbond7512No',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.tripNo,
      type: 'textfield',
      name: 'tripNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.masterBol,
      type: 'textfield',
      name: 'masterBol',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.inbondDestination,
      type: 'textfield',
      name: 'inbondDestination',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.inbondDate,
      type: 'daterange',
      name: 'inbondDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.firstPort,
      type: 'textfield',
      name: 'firstPort',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.etaFirstPortUS,
      type: 'daterange',
      name: 'etaFirstPortUS',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.actionCode,
      type: 'checkbox',
      name: 'msgFunction',
      operatorType: IN,
      options: [
        {
          label: 'Original',
          value: '64',
          filterValue: {
            value: '64'
          }
        },
        {
          label: 'Change',
          value: '4',
          filterValue: {
            value: '4'
          }
        },
        {
          label: 'Cancel',
          value: '1',
          filterValue: {
            value: '1'
          }
        },
        {
          label: '1 - Arrive entire in-bond at destination.',
          value: '1',
          filterValue: {
            value: '1'
          }
        },
        {
          label: '2 - Arrive bill of lading at destination.',
          value: '2',
          filterValue: {
            value: '2'
          }
        },
        {
          label: '3 - Arrive container/equipment at destination.',
          value: '3',
          filterValue: {
            value: '3'
          }
        },
        {
          label: '5 - Export entire in bond from destination.',
          value: '5',
          filterValue: {
            value: '5'
          }
        },
        {
          label: '6 - Export bill of lading from destination port.',
          value: '6',
          filterValue: {
            value: '6'
          }
        },
        {
          label: '7 - Export container/equipment from destination port.',
          value: '7',
          filterValue: {
            value: '7'
          }
        },
        {
          label: 'A -Transfer of in bond liability for entire in bond. Not used for Air.',
          value: 'A',
          filterValue: {
            value: 'A'
          }
        },
        {
          label: 'Z - Diversion request',
          value: 'Z',
          filterValue: {
            value: 'Z'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'radio',
      name: 'createdDate',
      operatorType: BETWEEN,
      options: [
        {
          label: 'Past 15 days',
          value: 'past_15_days',
          filterValue: {
            type: 'date',
            value: {
              startDate: moment().subtract(15, 'days').format(),
              endDate: moment().format()
            }
          }
        },
        {
          label: 'Past 30 days',
          value: 'past_30_days',
          filterValue: {
            type: 'date',
            value: {
              startDate: moment().subtract(30, 'days').format(),
              endDate: moment().format()
            }
          }
        },
        {
          label: 'Past 60 days',
          value: 'past_60_days',
          filterValue: {
            type: 'date',
            value: {
              startDate: moment().subtract(60, 'days').format(),
              endDate: moment().format()
            }
          }
        }
      ]
    },
    {
      label: 'Last Modified Date',
      type: 'radio',
      name: 'updatedDate',
      operatorType: BETWEEN,
      options: [
        {
          label: 'Past 15 days',
          value: 'past_15_days',
          filterValue: {
            type: 'date',
            value: {
              startDate: moment().subtract(15, 'days').format(),
              endDate: moment().format()
            }
          }
        },
        {
          label: 'Past 30 days',
          value: 'past_30_days',
          filterValue: {
            type: 'date',
            value: {
              startDate: moment().subtract(30, 'days').format(),
              endDate: moment().format()
            }
          }
        },
        {
          label: 'Past 60 days',
          value: 'past_60_days',
          filterValue: {
            type: 'date',
            value: {
              startDate: moment().subtract(60, 'days').format(),
              endDate: moment().format()
            }
          }
        }
      ]
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          fullWidth: true,
          label: 'Accepted with Warning',
          value: 'accepted_with_warning',
          filterValue: {
            value: '1015'
          }
        },
        {
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        {
          label: 'Status Notification',
          value: 'status_notification',
          filterValue: {
            value: '1006'
          }
        },
        {
          label: 'Preliminary',
          value: 'preliminary',
          filterValue: {
            value: '1002'
          }
        },
        {
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1007'
          }
        },
        {
          label: 'PL',
          value: 'pl',
          filterValue: {
            value: '1009'
          }
        },
        {
          label: 'CP',
          value: 'cp',
          filterValue: {
            value: '1010'
          }
        }
      ]
    }
  ]

  function handleCloneInbondArrival(data) {
    createRecord.execute(
      AceHwyInbondArrivalApiUrls.CLONE,
      data,
      (data) => {
        if(data.filerCode){
          history.push(pathMap.ABI_INBOND_EVENT_ADD_VIEW, InbondEventFormProperties.toClientDataFormat(data))
        }else{
          history.push(pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_ADD_VIEW, InbondArrivalFormProperties.toClientDataFormat(data))
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteInbondArrival() {
    
    if (confirmDialog.inbondArrival) {
      deleteRecord.execute(
        AceHwyInbondArrivalApiUrls.DELETE,
        confirmDialog.inbondArrival,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, inbondArrival: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'upload']} />,
              onClick: () => setUploadInbondEventDialog({ open: true, tableRef: tableRef })
            },
            label: 'Upload inbond arrival/export Stand Alone'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_ADD_VIEW)
            },
            label: 'Create inbond arrival/export from manifest'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ABI_INBOND_EVENT_ADD_VIEW)
            },
            label: 'Create inbond arrival/export Stand Alone'
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: AceHwyInbondArrivalApiUrls.EXPORT }}
        fetch={{ url: AceHwyInbondArrivalApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if(rowData.status === '1008'){
            null === rowData.tripNo ? history.push(`${location.pathname}-event/view/${rowData.id}`) : history.push(`${location.pathname}/view/${rowData.id}`)
          }else{
            null === rowData.tripNo ? history.push(`${location.pathname}-event/edit/${rowData.id}`) : history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }}
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) => {
              null === rowData.tripNo ? history.push(`${location.pathname}-event/edit/${rowData.id}`) : history.push(`${location.pathname}/edit/${rowData.id}`)
            },
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneInbondArrival
          },
          {
            disabled: (rowData) => rowData.status !== '1005',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setConfirmDialog({ open: true, inbondArrival: rowData })
          },
        ]}
        search={{ field: 'tripNo', operator: LIKE }}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <InbondEventUploadDialog
        open={uploadInbondEventDialog}
        onClose={() => setUploadInbondEventDialog({ open: false, tableRef: null })}
        showNotification={showNotification}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() =>
          setConfirmDialog({ open: false, inbondArrival: null })
        }
        confirmDialog={handleDeleteInbondArrival}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default TablePage
