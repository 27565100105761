import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AceHwyInbondArrivalKeys from 'src/constants/locale/key/AceHwyInbondArrival'
import { Card, CardContent, Grid, Divider, Hidden } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form"
import { useLocation } from 'react-router-dom'
import {FirmCodesAutocompleteField} from 'src/components/na'
import NaUSPortAutocompleteField from 'src/components/na/autocomplete/codemaintenance/NaUSPortAutocompleteField';
import CngSection from '../../components/cngcomponents/CngSection'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TemplateSelectDialogWithOptionACEHWYManifest from 'src/components/aciacehighway/TemplateSelectDialogWithOptionACEHWYManifest'

const {
  button: { CngButton },
  form: {
    adapter: { 
      useFormAdapter:{ useField } 
    },
    field: {
      CngTextField,
      CngDateTimeField,
      CngDateField,
      CngCodeMasterAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  tripNo: "",
  etaFirstPortUS: "",
  firstPort: "",
  inbond7512No: "",
  inbondDate: "",
  inbondDestination: "",
  firmsCode: "",
  msgFunction: "64",
  messageCode: "",
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  version: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_INBOND_ARRIVAL)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, getValues, reset, trigger } = useFormContext();

  const [manifestDialog, setManifestDialog] = useState(false)

  const location = useLocation();
  const addPage = location.pathname.toString().indexOf("add") != -1 ? true : false
  const viewPage = location.pathname.toString().indexOf("view") != -1 ? true : false
  const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false

  const [messageCode] = useField("messageCode");
  let showMessageCodeValue = messageCode.value

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let aceHwyInbondArrival = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.TITLE
    )
    let tripNo = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.TRIP_NO
    )
    let etaFirstPortUS = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.ETA_FIRST_PORT_US
    )
    let firstPort = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.FIRST_PORT
    )
    let inbond7512No = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.INBOND7512_NO
    )
    let inbondDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.INBOND_DATE
    )
    let inbondDestination = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.INBOND_DESTINATION
    )
    let firmsCode = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.FIRMS_CODE
    )
    let msgFunction = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.MSG_FUNCTION
    )

    let messageCode = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.MESSAGE_CODE
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.ID
    )
    let createdBy = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.UPDATED_DATE
    )
    let submittedBy = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.SUBMITTED_DATE
    )
    let version = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.VERSION
    )
    let partyId = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.PARTY_ID
    )
    let arrivalStatus = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.ARRIVAL_STATUS
    )
    let exportStatus = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.EXPORT_STATUS
    )

    return {
      aceHwyInbondArrival,
      id,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      version,
      partyId,
      tripNo,
      etaFirstPortUS,
      firstPort,
      inbond7512No,
      inbondDate,
      inbondDestination,
      firmsCode,
      msgFunction,
      messageCode,
      arrivalStatus,
      exportStatus
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card variant='outlined' style={{ padding: 16 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm='auto'>
              <CngButton
                onClick={() => setManifestDialog(true)}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'money-check-edit']} />}
                color='secondary'
              >
                Use manifest
                </CngButton>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} sm='auto'>
                <Divider orientation='vertical' />
              </Grid>
            </Hidden>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
      <CngSection title={translatedTextsObject.aceHwyInbondArrival}>
        <Grid container spacing={1}><CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="id"
                  label={translatedTextsObject.id}
                  disabled={true}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="createdBy"
                  label={translatedTextsObject.createdBy}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="createdDate"
                  label={translatedTextsObject.createdDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="updatedBy"
                  label={translatedTextsObject.updatedBy}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="updatedDate"
                  label={translatedTextsObject.updatedDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="submittedBy"
                  label={translatedTextsObject.submittedBy}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="submittedDate"
                  label={translatedTextsObject.submittedDate}
                  disabled={disabled}
                />
              </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.messageCode}>
            <CngCodeMasterAutocompleteField
              name="messageCode"
              label={translatedTextsObject.messageCode}
              disabled={disabled}
              codeType='ACE_HWY_INBOND_TYPE'
              isRequired
              size='small'
                key={getValues("messageCode")}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tripNo}>
            <CngTextField
              name="tripNo"
              label={translatedTextsObject.tripNo}
              disabled={disabled}
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.etaFirstPortUS}>
            <CngDateTimeField
              name="etaFirstPortUS"
              label={translatedTextsObject.etaFirstPortUS}
              format="YYYY-MM-DD HH:mm"
              disabled={disabled}
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.firstPort}>
            <NaUSPortAutocompleteField
              name="firstPort"
              label={translatedTextsObject.firstPort}
              disabled={disabled}
              isRequired
              size='small'
                key={getValues("firstPort")}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.inbond7512No}>
            <CngTextField
              name="inbond7512No"
              label={translatedTextsObject.inbond7512No}
              disabled={disabled}
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={showMessageCodeValue!=="833"}>
            <CngDateTimeField
              name="inbondDate"
              label={"Inbond Departure Date"}
              format="YYYY-MM-DD HH:mm"
              disabled={disabled}
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={showMessageCodeValue==="833"}>
            <CngDateTimeField
              name="inbondDate"
              label={translatedTextsObject.inbondDate}
              format="YYYY-MM-DD HH:mm"
              disabled={disabled}
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.inbondDestination}>
            <NaUSPortAutocompleteField
              name="inbondDestination"
              label={translatedTextsObject.inbondDestination}
              disabled={disabled}
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.firmsCode}>
            <FirmCodesAutocompleteField
                required={'98' === getValues("messageCode") && '64' === getValues("msgFunction")}
              name="firmsCode"
              label={translatedTextsObject.firmsCode}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </CngSection>
    </Grid>
    <CngGridItem xs={12} shouldHide={shouldHideMap.msgFunction}>
      <CngSection title={translatedTextsObject.msgFunction}>
        <CngCodeMasterAutocompleteField
          name='msgFunction'
          label={translatedTextsObject.msgFunction}
          disabled={disabled}
          codeType={
            editPage || viewPage
              ? 'MESSAGE_FUNCTION_INBOND'
              : addPage && 'MESSAGE_FUNCTION_INBOND_ADD'
          }
        />
      </CngSection>
    </CngGridItem>

      <TemplateSelectDialogWithOptionACEHWYManifest
        manifestDialog={manifestDialog}
        setManifestDialog={setManifestDialog}
        showNotification={showNotification}
        translatedTextsObject={translatedTextsObject}
        setValue={setValue}
        reset={reset}
        trigger={trigger}
      />
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  console.log("toClientDataFormat: "+ JSON.stringify(serverData))
  // let localData = DataFlattener.parse(serverData);

  if(serverData.etaFirstPortUS !== null)
    serverData.etaFirstPortUS = moment(serverData.etaFirstPortUS).tz("Canada/Eastern").format('YYYY-MM-DD HH:mm');
  
  if(serverData.inbondDate !== null)
    serverData.inbondDate = moment(serverData.inbondDate).tz("Canada/Eastern").format('YYYY-MM-DD HH:mm');

  return serverData;
}

function toServerDataFormat(localData) {
  console.log("toServerDataFormat: "+ JSON.stringify(localData))

  localData.etaString = moment(localData.etaFirstPortUS).format('YYYY-MM-DD HH:mm'); //pass string to backend convert to server timezone
  localData.etaFirstPortUS = moment(localData.etaFirstPortUS).format('YYYY-MM-DDTHH:mmZ');

  localData.inbondDateString = moment(localData.inbondDate).format('YYYY-MM-DD HH:mm'); //pass string to backend convert to server timezone
  localData.inbondDate = moment(localData.inbondDate).format('YYYY-MM-DDTHH:mmZ');

  // return DataFlattener.unflatten(localData);
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
