import { Box, Card } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router-dom'
import InbondViewPage from 'src/views/aceHwyInbondArrival/InbondViewForm'

function ViewPage({ history, showNotification }) {
  const { id } = useParams()

  return (
    <Card variant='outlined'>
      <Box padding={2}>
        <InbondViewPage
          history={history}
          showNotification={showNotification}
          id={id}
        />
      </Box>
    </Card>
  )
}

export default ViewPage
