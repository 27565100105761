import AceHwyInbondArrivalApiUrls from 'src/apiUrls/AceHwyInbondArrivalApiUrls'
import React, { useEffect, useState } from 'react'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import AceHwyInbondArrivalKeys from 'src/constants/locale/key/AceHwyInbondArrival'
import Namespace from 'src/constants/locale/Namespace'
import CngSection from '../../components/cngcomponents/CngSection'
import moment from 'moment'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import clsx from 'clsx'

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      padding: '12px 16px',
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    }
  }
}))

function ActivityLog({ showNotification, id, isViewOnly = false }) {
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { fetchRecords } = useServices()
  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_INBOND_ARRIVAL])
  const translatedTextsObject = makeTranslatedTextsObject()
  const classes = useStyles()

  useEffect(() => {
    fetchRecords.execute(
      AceHwyInbondArrivalApiUrls.GET_AUDIT_LOGS,
      {
        filters: [
          {
            field: 'keyReferenceNo',
            operator: EQUAL,
            value: 'ACE_HIGHWAY_INBOND_ARRIVAL/EXPORT_' + id
          }
        ]
      },
      (res) => setData(res.content)
    )
  }, [])

  function makeTranslatedTextsObject() {
    let activityLogTitle = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.ACTIVITYLOG_TITLE
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.CREATED_DATE
    )
    let loginUserId = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.LOGIN_USER_ID
    )
    let functionCode = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.FUNCTION_CODE
    )
    let auditDescription = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.AUDIT_DESC
    )

    return {
      activityLogTitle,
      createdDate,
      loginUserId,
      functionCode,
      auditDescription
    }
  }

  const columns = [
    { field: 'userName', title: translatedTextsObject.loginUserId },
    { field: 'functionCode', title: translatedTextsObject.functionCode },
    {
      field: 'auditDescription',
      title: translatedTextsObject.auditDescription
    },
    {
      title: translatedTextsObject.createdDate,
      render: (data) =>
        data.createdDate &&
        moment(data.createdDate).format('D MMM YYYY - HH:mm')
    }
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <CngSection title={translatedTextsObject.activityLogTitle}>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((d) => (
                <TableRow key={d.id}>
                  {columns.map((column, index) => (
                    <TableCell key={index}>
                      {column.render ? column.render(d) : d[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </CngSection>
  )
}
export default ActivityLog
