import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import AceHighwayResponseApiUrls from 'src/apiUrls/AceHighwayResponseApiUrls'
import Namespace from 'src/constants/locale/Namespace'
import AceHwyInbondArrivalKeys from 'src/constants/locale/key/AceHwyInbondArrival'
import CngSection from '../../components/cngcomponents/CngSection'
import moment from 'moment-timezone'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import clsx from 'clsx'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      padding: '12px 16px',
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    }
  }
}))

function InbondResponse(props) {
  const { id, serverDataRef } = props

  const [data, setData] = useState([])
  const [lookups, setLookups] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { fetchRecords } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_RESPONSE])
  const translatedTextsObject = makeTranslatedTextsObject()
  const classes = useStyles()

  useEffect(() => {
    Promise.all([
      fetchRecords.execute(
        AceHighwayResponseApiUrls.GET,
        {
          filters: [
            { field: 'headerId', operator: EQUAL, value: id },
            { field: 'documentType', operator: EQUAL, value: 'ACE_INBOND' }
          ]
        },
        (res) => res.content
      ),
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACI_HW_SUBMISSION_TYPE'
          }
        ],
        undefined,
        'code'
      )
    ]).then(([data, submissionType]) => {
      setData(data)
      setLookups({ submissionType })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let responseTitle = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.RESPONSE_TITLE
    )

    let batchId = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.BATCH_ID
    )

    let cbsaProcessingDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.CBSA_PROCESSING_DATE
    )

    let CBPRefNo = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.CBP_REF_NO
    )

    let tripNo = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.TRIP_NO
    )

    let responseCode = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.RESPONSE_CODE
    )

    let responseText = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.RESPONSE_TEXT
    )

    let responseDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.RESPONSE_DATE
    )

    let submissionType = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.SUBMISSION_TYPE
    )

    return {
      responseTitle,
      batchId,
      cbsaProcessingDate,
      CBPRefNo,
      tripNo,
      responseCode,
      responseText,
      responseDate,
      submissionType
    }
  }

  const columns = [
    { field: 'cbpRefNo', title: translatedTextsObject.CBPRefNo },
    { field: 'responseCode', title: translatedTextsObject.responseCode },
    { field: 'responseText', title: translatedTextsObject.responseText },
    { field: 'tripNo', title: translatedTextsObject.tripNo },
    {
      title: translatedTextsObject.responseDate,
      render: (data) =>
        data.responseDate &&
        moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY - HH:mm')
    },
    {
      title: translatedTextsObject.submissionType,
      render: (data) =>
        lookups && lookups['submissionType'][data.submissionType] !== undefined
          ? lookups['submissionType'][data.submissionType]
          : data.submissionType
    }
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  return (
    <CngSection title={translatedTextsObject.responseTitle}>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((d) => (
                <TableRow key={d.id}>
                  {columns.map((column, index) => (
                    <TableCell key={index}>
                      {column.render ? column.render(d) : d[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </CngSection>
  )
}

export default InbondResponse
