import AceHwyInbondArrivalApiUrls from 'src/apiUrls/AceHwyInbondArrivalApiUrls'
import { CircularProgress, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import AceHwyInbondArrivalKeys from 'src/constants/locale/key/AceHwyInbondArrival'
import pathMap from 'src/paths/pathMap'
import CngSection from '../../components/cngcomponents/CngSection'
import CngField from '../../components/cngcomponents/CngField'
import ResponseMessage from './ResponseMessage'
import ActivityLog from './ActivityLog'
import moment from 'moment-timezone'
import FormProperties from './FormProperties'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function ViewPage({ history, showNotification }) {
  const { id } = useParams()

  const [lookups, setLookups] = useState(null)
  const [data, setData] = useState(null)
  const { fetchRecord } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_INBOND_ARRIVAL)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        AceHwyInbondArrivalApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      // Message code
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HWY_INBOND_TYPE' }],
        undefined,
        'code'
      ),
      // Message function
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'MESSAGE_FUNCTION_INBOND'
          }
        ],
        undefined,
        'code'
      )
    ]).then(([data, messageCode, messageFunc]) => {
      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)
      }

      var clientData = FormProperties.toClientDataFormat(data)
      setData(clientData)
      setLookups({ messageCode, messageFunc })
    })
  }, [])

  if (data === null) {
    return <CircularProgress />
  }

  function makeTranslatedTextsObject() {
    let aceHwyInbondArrival = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.TITLE
    )
    let tripNo = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.TRIP_NO
    )
    let etaFirstPortUS = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.ETA_FIRST_PORT_US
    )
    let firstPort = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.FIRST_PORT
    )
    let inbond7512No = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.INBOND7512_NO
    )
    let inbondDate = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.INBOND_DATE
    )
    let inbondDestination = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.INBOND_DESTINATION
    )
    let firmsCode = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.FIRMS_CODE
    )
    let msgFunction = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.MSG_FUNCTION
    )
    let messageCode = translate(
      Namespace.ACE_HIGHWAY_INBOND_ARRIVAL,
      AceHwyInbondArrivalKeys.MESSAGE_CODE
    )

    return {
      aceHwyInbondArrival,
      tripNo,
      etaFirstPortUS,
      firstPort,
      inbond7512No,
      inbondDate,
      inbondDestination,
      firmsCode,
      msgFunction,
      messageCode
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.aceHwyInbondArrival}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CngField label={translatedTextsObject.messageCode}>
                  {lookups
                    ? lookups['messageCode'][data.messageCode]
                    : data.messageCode}
                </CngField>
              </Grid>
              <Grid item xs={6}>
                <CngField label={translatedTextsObject.tripNo}>
                  {data.tripNo}
                </CngField>
              </Grid>
              <Grid item xs={6}>
                <CngField label={translatedTextsObject.etaFirstPortUS}>
                  {data.etaFirstPortUS && moment(data.etaFirstPortUS).format('D MMM YYYY, HH:mm')}
                </CngField>
              </Grid>
              <Grid item xs={6}>
                <CngField label={translatedTextsObject.firstPort}>
                  {data.firstPort}
                </CngField>
              </Grid>
              <Grid item xs={6}>
                <CngField label={translatedTextsObject.inbond7512No}>
                  {data.inbond7512No}
                </CngField>
              </Grid>
              <Grid item xs={6}>
                <CngField label={translatedTextsObject.inbondDate}>
                  {data.inbondDate && moment(data.inbondDate).format('D MMM YYYY, HH:mm')}
                </CngField>
              </Grid>
              <Grid item xs={6}>
                <CngField label={translatedTextsObject.inbondDestination}>
                  {data.inbondDestination}
                </CngField>
              </Grid>
              <Grid item xs={6}>
                <CngField label={translatedTextsObject.firmsCode}>
                  {data.firmsCode}
                </CngField>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.msgFunction}>
            <CngField label={translatedTextsObject.msgFunction}>
              {lookups && lookups['messageFunc'][data.msgFunction] !== undefined
                ? lookups['messageFunc'][data.msgFunction]
                : data.msgFunction}
            </CngField>
          </CngSection>
        </Grid>
        <Grid item xs={12}>
          <ResponseMessage id={id} />
        </Grid>
        <Grid item xs={12}>
          <ActivityLog id={id} />
        </Grid>
      </Grid>
    </>
  )
}

export default ViewPage
